/* General styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
    background-color: #f5f5f5;
}

.pre-order-table * {
    font-family: sans-serif !important;
}

h1 {
    text-align: center;
}

form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
}

form div {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="text"],
input[type="date"],
input[type="number"],
input[type="password"],
input[type="email"],
select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

select {
    height: 100px;
}

button[type="submit"] {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    width: 100%;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}

/* Pagination styles */
.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    flex-wrap: wrap;
    gap: 5px;
}

.pagination .page-link {
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
}

.pagination .page-link:hover {
    background-color: #0056b3;
}

/* Group list styles */
ul {
    list-style: none;
    padding: 0;
}

li {
    background: #fff;
    margin: 10px 0;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

li span {
    display: block;
    margin-bottom: 10px;
}

li button {
    background-color: #dc3545;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

li button:hover {
    background-color: #c82333;
}

li button:nth-of-type(1) {
    background-color: #28a745;
}

li button:nth-of-type(1):hover {
    background-color: #218838;
}

ul.pagination {
    margin-top: 15px;
}

li.page-item {
    background: transparent;
    padding: 0;
}

li.page-item.active button {
    background: #1D3865;
}

li.page-item button {
    margin-right: 0;
}

.tableWrapper.pre-order-table  tbody tr td span {
    display: none;
}

form .flexRow {
    flex-wrap: wrap;
}

@media (max-width: 575px) {

    .tableWrapper.pre-order-table {
        display: block !important;
    }

    .tableWrapper.pre-order-table  tbody tr td {
        margin-top: 7px;
        padding: 10px;
    }

    .tableWrapper.pre-order-table  tbody tr td span {
        display: inline;
        font-weight: bold;
    }

    .tableWrapper.pre-order-table thead {
        display: none;
    }

    .tableWrapper.pre-order-table  tbody tr {
        display: grid;
        border: 1px solid;
        padding: 15px;
        margin-top: 15px;
    }

    .tabs-header > div {
        display: grid;
        grid-gap: 15px;
    }

    .tabs-header > div div {
        margin: 0;
        padding-bottom: 7px;
    }

    .tabs-header + div select, .tabs-header + div + div select {
        height: 38px;
    }
}

.tabs-header + div + div + div ul {
    flex-wrap: wrap;
}

.group-form button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    width: 100%;
}

.group-form select, .tableWrapper.pre-order-table select {
    height: 40px;
}

div.modal .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

div.modal .modal-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
}

div.modal .modal-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
}

div.modal .modal-message {
    margin-bottom: 20px;
}

div.modal .modal-actions {
    display: flex;
    justify-content: space-around;
}

div.modal .btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

div.modal .btn-confirm {
    background-color: #28a745;
    color: white;
}

div.modal .btn-cancel {
    background-color: #dc3545;
    color: white;
}