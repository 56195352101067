.orders-actions > div {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
}
.orders-actions div * {
    margin-right: 0;
    height: auto;
    display: block;
    width: 100%;
    border: none;
}