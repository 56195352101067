@font-face {
    font-family: mardotoRegular;
    src: url('src/assets/fonts/Mordoto/Mardoto-Regular.ttf');
}

@font-face {
    font-family: mardotoMedium;
    src: url('src/assets/fonts/Mordoto/Mardoto-Medium.ttf');
}

@font-face {
    font-family: mardotoBold;
    src: url('src/assets/fonts/Mordoto/Mardoto-Bold.ttf');
}

@font-face {
    font-family: montserratMedium;
    src: url('src/assets/fonts/Montserrat/Montserrat-Medium.otf');
}

@font-face {
    font-family: montserratRegular;
    src: url('src/assets/fonts/Montserrat/Montserrat-Regular.otf');
}

html * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: mardotoRegular, sans-serif;
    color: black;
}

input, button, select {
    border : none;
    outline: none;
    font-family: mardotoRegular,sans-serif;
}

/* width */
::-webkit-scrollbar {
    width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.react-responsive-modal-closeButton{
    top:0 !important;
    right: 14px !important;
}
@media (max-width: 680px){
    .react-responsive-modal-closeButton{
        top:14px !important;
        right: 14px !important;
    }
}
.react-responsive-modal-closeButton svg{
    width: 14px;
}

a{
    text-decoration: none;
}
.custom_spinner{
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: rgba(0,0,0,.8);
    position: fixed;
    display: flex;
    justify-content: center;
    align-content: center;
}
.spinnerContent {
    position: absolute!important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sub-categories-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.sub-categories-wrapper > div {
    display: flex;
    margin: 15px;
}

.sub-categories-wrapper > div > p {
    margin-left: 5px;
}