/*Main Title*/
label{
    display: inline-flex;
}
.mainTitle {
    font-size: 32px;
    line-height: 48px;
    font-weight: 500;
    color: black;
    fontFamily: 'montserratMedium', sans-serif;
    margin-bottom: 24px;
}

.link {
    font-size: 14px;
    color: #1D3865;
    line-height: 16px;
    font-weight: 500;
}

.bold {
    font-size: 14px !important;
    line-height: 16px !important;
    color: black !important;
    font-weight: 900 !important;
}

.productImage {
    width: 80px;
    height: 80px;
    object-fit: cover;
}
.productImage.customPriceEditProductImage {
    width: 54px;
    height: 54px;
    object-fit: cover;
}

.redTitle {
    color: #EB5757 !important;
}

.greenTitle {
    color: #219653 !important;
}

body .titleUppercase {
    text-transform: capitalize;
}

/*input with title start*/
.inputWithText {
    position: relative;
}


.inputWithText.updateRateModal input {
    width: 65px;
    height: 32px;
}

.inputWithText.updateRateModal input::-webkit-outer-spin-button,
.inputWithText.updateRateModal input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.inputWithText input {
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    padding: 0 20px 0 10px;
}

.inputWithText input:focus {

}

.inputWithText input:disabled {

}

.inputWithText p {
    position: absolute;
    right: 5px;
    top: 29%;
    color: black;
    font-size: 14px;
}

/*input with title end*/


/*Table start*/

.space-between-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.see-more-btn {
    font-size: 12px;
    color: #CCA653;
    fontFamily: 'montserratMedium', sans-serif;
    line-height: 16px;
    cursor: pointer;
}

.tableWrapper {
    max-width: 1200px;
    width: 100%;
    margin: auto;
}

.tableWrapper.rateTable.equalWidthTable thead tr th {
    width: 25%;
    text-align: center;
}

.tableWrapper.rateTable.equalWidthTable tbody tr td {
    width: 25%;
    text-align: center;
}

.tableWrapper.rateTable.equalWidthTable.rate-metal tbody tr td {
    padding: 22.5px 11px;
}


.tableWrapper.rateTable td {
    font-size: 14px;
    line-height: 16px;
    color: black;
    font-weight: 900;
}

.tableWrapper.rateTable th {
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, .5);
    font-weight: bold;
}

.table {
    width: 100%;
    border-spacing: 0;
}

.table thead tr {
    vertical-align: inherit;
}

.table thead tr th {
    padding: 30px 30px 30px 18px;
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, .5);
    text-transform: uppercase;
}

.table tbody tr:nth-child(odd) {
    background-color: rgba(195, 195, 195, 0.1);
}

.table tbody tr td {
    padding: 30px 30px 30px 18px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tableButtonWrapper {
    margin-right: 15px;
}

.tableButtonWrapper > div:not(:last-child) {
    margin-right: 8px;
}

.flexRow {
    display: flex;
    align-items: center;
    justify-content: center;
}

/*Table End*/

/*Table card start*/
.cardContainer {
    width: 100%;
    padding: 17px 10px;
    background-color: white;
    display: none;
}

.cardContainer.sellerProductCard > img {
    width: 80px;
    height: 80px;
}

.cardContainer:nth-child(odd) {
    background-color: #F9F9F9;
}

.cardContainer li:not(:last-child) {
    padding-bottom: 8px;
}

.cardContent.sellerProductContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
}

.cardContent.sellerProductContent > li {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.cardContent.sellerProductContent > li .cardValue {
    padding-left: 0;
    white-space: normal;
}

.cardContent.sellerProductContent > li .cardKey {
    color: rgba(0, 0, 0, .5);
    padding-bottom: 2px;
}

.customPriceCheckAllCheckbox {
    display: none;
    margin-left: 10px;
    background-color: #F9F9F9;
}

.customPriceImage {
    display: flex;
    align-items: flex-start;
}

.customPriceImage p {
    margin-left: 5px;
}

.customPriceImage img {
    width: 69px;
    height: 69px;
    margin-left: 10px;
}

.cardItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cardItem a {
    width: 100%;
}

.cardKey {
    font-size: 14px;
    line-height: 16px;
    color: black;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.cardValue {
    font-family: 'mardotoMedium', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: black;
    font-weight: 500;
    max-width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 7px;
}

.cardButtonWrapper {
    margin: auto;
    width: 100%;
}

.cardButtonWrapper > div:not(:last-child) {
    margin-right: 8px;
}

.flexEnd {
    display: flex;
    justify-content: flex-end;
}

.flexEnd.flexStart {
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;
}

.cardButtonWrapper.flexEnd.seller-offer-buttons > div {
    width: 209px;
}

/*payment info start*/

.detailOfferWrapper {
    display: flex;
    align-items: flex-start;
    background-color: rgba(195, 195, 195, .1);
    padding: 20px;
    margin-bottom: 20px;
}

.detailOfferWrapper > img {
    width: 140px;
    height: 140px;
    flex: 0 0 140px;
    margin-right: 18px;
    object-fit: cover;
}

.detailOfferWrapper .payment-info_container {
    max-width: unset;
    width: calc(100% - 166px);
}

.payment-info_container {
    max-width: 894px;
    width: 100%;
    margin-top: 20px;
}

.payment-info_container.buyerDetailsOffer {
    max-width: 1200px;
    width: 100%;
    margin-top: 20px;
    background-color: rgba(195, 195, 195, 0.1);
    padding: 18px;
}

.payment-info_container.buyerDetailsOffer .payment-info-item p {
    font-weight: 600;
}

.payment-info-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
}

.payment-info-title span {
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: #1D3865;
    padding-right: 10px;
}

.payment-info_container .payment-info-item {
    list-style-type: none;
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
}

.payment-info_container .payment-info-item span {
    flex: 1;
    overflow: hidden;
}

.payment-info_container .payment-info-item p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
}

/*.detailOfferWrapper .payment-info-item p:last-child{*/
/*    white-space: nowrap;*/
/*    overflow: hidden;*/
/*    text-overflow: ellipsis;*/
/*}*/

/*payment info end*/

/*Table card end*/

/*chart */

.chart {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
}

canvas {
    touch-action: pan-x !important;
}

.chart > div:not(:last-child) {
    margin-right: 15px;
}

.chart .chart-radio-wrapper {
    margin-top: 13px;
    display: flex;
    align-items: center;
}

.chart .custom-select {
    width: 150px;
}

@media only screen and (max-width: 991px) {
    .chart {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 800px) {
    .tableWrapper {
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .tableWrapper::-webkit-scrollbar {
        display: none;
    }

    .detailOfferWrapper .payment-info_container {
        max-width: unset;
        width: calc(100% - 100px);
    }

    .payment-info-title.seller-offer {
        flex-direction: column;
        align-items: flex-start;
    }

    .payment-info-title.seller-offer span {
        padding-bottom: 7px;
    }

    .detailOfferWrapper > img {
        width: 80px;
        height: 80px;
        flex: 0 0 80px;
        object-fit: cover;
    }

}

@media only screen and (max-width: 600px) {
    .tableWrapper.rateTable.equalWidthTable tbody input {
        padding: 0 5px 0;
    }

    .tableWrapper.rateTable.equalWidthTable tbody tr td {
        padding: 2px;
        max-width: 63px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .chart-wrapper {
        overflow-x: scroll;
        /*max-width: 1140px;*/
        scroll-behavior: inherit;
    }

    .chart-width {
        width: 800px
    }
}

@media only screen and (max-width: 500px) {
    .mainTitle {
        font-size: 18px;
        line-height: 33px;
        margin-bottom: 5px;
    }

    .tableWrapper {
        display: none;
    }

    .tableWrapper.rateTable {
        display: block;
    }

    .cardContainer.sellerProductCard {
        display: flex;
        align-items: flex-start;
    }

    .cardContainer {
        display: block;
    }

    .customPriceCheckAllCheckbox {
        display: block;
    }

    .cardButtonWrapper.flexEnd.seller-offer-buttons {
        flex-direction: column;
    }

    .cardButtonWrapper.flexEnd.seller-offer-buttons > div {
        margin-bottom: 7px;
        width: 100%;
    }
}

